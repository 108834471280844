import axios from "axios";
import errorParser from "../components/ErrorParser/ErrorParser";
import moment from "moment";
import React from "react";
import {toast} from "react-toastify";

export const delay = ms => new Promise(res => setTimeout(res, ms));

export function getLocationIcon(location, size = 'small', className = '') {

    if (location.image && location.image.url.length > 0) {

        let url = location.image.url

        if (location.image.formats) {
            if (size === 'small' && location.image.formats.small) {
                url = location.image.formats.small.url
            }
        }

        return <div className={"location-image " + size + " " + className}>
            <img src={process.env.REACT_APP_API + url} alt={""}/>
        </div>
    }

    if (location.icon && location.icon.length > 0) {
        return <img src={"/images/locations_icons/" + location.icon}
                    className={"location-icon " + size + " " + className} alt={""}/>
    }

    return <img src={"/images/locations_icons/1.svg"}
                className={"location-icon " + size + " " + className} alt={""}/>
}

export function rgba2hex(orig) {
    var a,
        rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
        alpha = ((rgb && rgb[4]) || "").trim(),
        hex = rgb ?
            (rgb[1] | (1 << 8)).toString(16).slice(1) +
            (rgb[2] | (1 << 8)).toString(16).slice(1) +
            (rgb[3] | (1 << 8)).toString(16).slice(1) : orig;

    if (alpha !== "") {
        a = alpha;
    } else {
        a = '01';
    }
    // multiply before convert to HEX
    a = ((a * 255) | (1 << 8)).toString(16).slice(1)
    hex = hex + a;

    return hex.toString();
}

export function hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',';
    }
    throw new Error('Bad Hex');
}

export function addEvent(e, date) {
    e.preventDefault()
    window.location.href = '/events/add?date=' + moment(date.toString()).toISOString()
}

export async function base64File(url) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        };
    });
}

export function getAddress(user) {
    let address = '';

    if (user.address !== '') {
        address += user.address
        if (user.city !== '' || user.county !== '')
            address += ', ';
    }
    if (user.city !== '') {
        address += user.city

        if (user.county !== '')
            address += ', '
    }
    address += user.county
    return address
}

export function getNotificatioLink(type, data) {
    switch (type) {
        case 1:
        case 11:
        case 18:
        case 24:
            return '/events/' + data.id;
        case 2:
            return '/events/' + data.id + '/taste'
        case 14:
        case 15:
            return '/events/' + data.id + '/feedback-form'
        case 3:
            return '/events/' + data.id + '/contracts'
        case 4:
        case 8:
        case 9:
            return '/contracts';
        case 5:
            return '/events/' + data.id + '/staff'
        case 6:
            if (data.event) {
                return '/events/' + data.event
            } else {
                return '/';
            }
        case 7:
        case 10:
        case 12:
            return '/invoices';
        case 16:
        case 17:
            return '/contracts/' + data.id + '/annexes/';
        case 19:
            return '/events/' + data.id + '/food-menu';
        case 20:
            return '/events/' + data.id + '/drink-menu';
        case 21:
            return '/events/' + data.id + '/logistics';
        case 22:
            return '/menus/food-menus/' + data.id;
        case 23:
            return '/menus/drink-menus/' + data.id;
        default:
            return '/';
    }
}

export function getNotificatioText(notification, intl) {
    let content = ''
    if (notification)
        switch (notification.type) {
            case 1:
                content += intl.formatMessage({id: 'general.notification_1'});
                content += ' ' + moment(notification.entity.start).format('DD/MM/YYYY') + ' ' + intl.formatMessage({id: 'general.notification_1_expire'})
                    + ' ' + notification.entity.location.title + ' ';
                content += intl.formatMessage({id: 'general.notification_1_expire'}) + ' ';
                content += intl.formatMessage({id: 'general.notification_1_' + notification.data.days});
                break;
            case 2: // Pentru evenimentul x  nu s-a facut degustarea
                content += intl.formatMessage({id: 'general.notification_2_1'});
                content += ' ' + notification.entity.title + ' ';
                content += intl.formatMessage({id: 'general.notification_2_2'});
                break;
            case 3: // Pentru evenimentul x  nu s-a semnat contractul
                content += intl.formatMessage({id: 'general.notification_3_1'});
                content += ' ' + notification.entity.title + ' ';
                content += intl.formatMessage({id: 'general.notification_3_2'});
                break;
            case 4: //  serie, numar
                content += intl.formatMessage({id: 'general.notification_4'});
                content += ' ' + (notification.entity ? (notification.entity.series + notification.entity.number) : '');
                break;
            // 5, X a acceptat/refuzat cererea de a participa la evenimnetul x din data de.
            case 5:
                content += notification.entity_2.name + ' ';
                if (notification.data.status === 'accepted') {
                    content += intl.formatMessage({id: 'general.notification_5_1'});
                } else {
                    content += intl.formatMessage({id: 'general.notification_5_2'});
                }
                content += intl.formatMessage({id: 'general.notification_5_3'}) + ' ' + notification.entity.title + ' ' +
                    intl.formatMessage({id: 'general.notification_5_4'}) + ' ' + moment(notification.entity.start).format('DD/MM/YYYY') + '.'
                break;
            case 6: // custom
                content += notification.data.message
                break;
            case 7: // custom
                content += intl.formatMessage({id: 'general.notification_7_1'})
                break;
            case 8: // 8, Contractul serie, numar a fost semnat de client
                content += intl.formatMessage({id: 'general.notification_8_1'});
                content += ' ' + notification.entity.number + ' ' + intl.formatMessage({id: 'general.notification_8_2'});
                break;
            case 9: // 9, Ai primit un contract nou
                content += intl.formatMessage({id: 'general.notification_9_1'});
                break;
            case 10: // 10 Ai primit o factura noua
                content += intl.formatMessage({id: 'general.notification_10_1'});
                break;
            case 11: // 11 Au fost adăugate mențiuni la evenimentul
                content += intl.formatMessage({id: 'general.notification_11'});
                if (notification.entity)
                    content += ' ' + notification.entity.title;
                break;
            case 12: // 12 Ai primit o factura noua
                content += intl.formatMessage({id: 'general.notification_12_1'});
                content += ' ' + notification.entity.series + notification.entity.number
                break;
            case 13: // 13 A fost ștearsă rezervarea
                content += intl.formatMessage({id: 'general.notification_13_1'});
                content += ' ' + notification.data.event
                break;
            case 14: // 14
                content += intl.formatMessage({id: 'general.notification_14_1'});
                content += ' ' + notification.entity.title;
                content += ' ' + intl.formatMessage({id: 'general.notification_14_2'});
                break;
            case 15: // 15
                content += intl.formatMessage({id: 'general.notification_15_1'});
                break;
            case 16: // 16
                content += intl.formatMessage({id: 'general.notification_16_1'});
                break;
            case 17: // 17
                content += intl.formatMessage({id: 'general.notification_17_1'});
                content += ' ' + notification.entity.series + notification.entity.number + ' ' + intl.formatMessage({id: 'general.notification_17_2'});
                break;
            case 18:
                content += intl.formatMessage({id: 'general.notification_18_1'});
                content += ' ' + notification.entity.title;
                break;
            case 19:
                content += intl.formatMessage({id: 'general.notification_19_1'});
                content += ' ' + notification.entity.title;
                break;
            case 20:
                content += intl.formatMessage({id: 'general.notification_20_1'});
                content += ' ' + notification.entity.title;
                break;
            case 21:
                content += intl.formatMessage({id: 'general.notification_21_1'});
                content += ' ' + notification.entity.title;
                break;
            case 22:
                content += intl.formatMessage({id: 'general.notification_22_1'});
                content += ' ' + notification.entity.title;
                break;
            case 23:
                content += intl.formatMessage({id: 'general.notification_23_1'});
                content += ' ' + notification.entity.title;
                break;
            case 24:
                content += intl.formatMessage({id: 'general.notification_24_1'});
                content += notification.entity.title + intl.formatMessage({id: 'general.notification_24_2'})
                    + notification.data.days +  intl.formatMessage({id: 'general.notification_24_3'});
                break;
            default:
                content += '';
                break;

        }
    return content
}

export async function markNotificationAsRead(id, intl, jwt) {
    axios.get(process.env.REACT_APP_API + '/notifications/mark-seen/' + id, {
        headers: {
            'Authorization': 'Bearer ' + jwt
        }
    })
        .then(async (response) => {
        })
        .catch(err => {
            errorParser(err, intl)
        })
}

export async function markNotificationAsDone(id, intl, jwt) {
    axios.get(process.env.REACT_APP_API + '/notifications/mark-done/' + id, {
        headers: {
            'Authorization': 'Bearer ' + jwt
        }
    })
        .then(async (response) => {
        })
        .catch(err => {
            errorParser(err, intl)
        })
}

export async function deleteNotification(id, intl, jwt) {
    axios.delete(process.env.REACT_APP_API + '/notifications/' + id, {
        headers: {
            'Authorization': 'Bearer ' + jwt
        }
    })
        .then(async (response) => {
        })
        .catch(err => {
            errorParser(err, intl)
        })
}

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function selectValueOnFocus(event) {
    event.target.select()
};


export async function downloadEfactura(id_download, id_upload, intl, jwt, setLoadingDownload) {
    await axios.get(process.env.REACT_APP_API + '/invoices/efactura/download/' + id_download + '/' + id_upload, {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/pdf',
            'Authorization': 'Bearer ' + jwt
        }
    })
        .then(async (response) => {
            if (response.data.stare === 'nok' && response.data.Messages.length > 0) {
                toast.error(response.data.Messages[0].message)

            } else if (response.data.status === 0) {
                toast.error(intl.formatMessage({id: 'errors.unknown'}))
            } else {
                const url = window.URL.createObjectURL(
                    response.data,
                );
                var a = document.createElement('a');
                a.href = url;
                a.download = id_upload + ".pdf";

                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
                setLoadingDownload(false)

            }
        })
        .catch(err => {
            errorParser(err, intl)
        })
    setLoadingDownload([])
}

export async function downloadXml(id_download, id_upload, intl, jwt, setLoadingDownload) {
    await axios.get(process.env.REACT_APP_API + '/invoices/efactura/download-xml/' + id_download + '/' + id_upload, {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/xml',
            'Authorization': 'Bearer ' + jwt
        }
    })
        .then(async (response) => {
            if (response.data.stare === 'nok' && response.data.Messages.length > 0) {
                toast.error(response.data.Messages[0].message)

            } else if (response.data.status === 0) {
                toast.error(intl.formatMessage({id: 'errors.unknown'}))
            } else {
                const url = window.URL.createObjectURL(
                    response.data,
                );
                var a = document.createElement('a');
                a.href = url;
                a.download = id_upload + ".xml";

                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
                setLoadingDownload([])

            }
        })
        .catch(err => {
            errorParser(err, intl)
        })
    setLoadingDownload([])
}