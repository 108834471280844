import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Dashboard from "./screens/Owner/Dashboard/Dashboard";
import {useState} from "react";
import {ToastContainer} from 'react-toastify';
import {IntlProvider} from "react-intl";
import LanguageContext from "./contexts/LanguageContext";
import {ro} from "./translations/ro/ro"
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import Login from "./screens/Auth/Login/Login";
import Account from "./screens/Owner/Settings/Account/Account";
import Company from "./screens/Owner/Settings/Company/Company";
import Locations from "./screens/Owner/Settings/Locations/Locations";
import AddLocation from "./screens/Owner/Settings/Locations/AddLocation";
import EditLocation from "./screens/Owner/Settings/Locations/EditLocation";
import DrinksMenus from "./screens/Owner/Nomenclature/Menus/DrinksMenus/DrinksMenus";
import AddDrinksMenu from "./screens/Owner/Nomenclature/Menus/DrinksMenus/AddDrinksMenu";
import EditDrinksMenu from "./screens/Owner/Nomenclature/Menus/DrinksMenus/EditDrinksMenu";
import ViewDrinkMenu from "./screens/Owner/Nomenclature/Menus/DrinksMenus/ViewDrinkMenu";
import CalendarScreen from "./screens/Owner/Calendar/Calendar";
import AddEvent from "./screens/Owner/Calendar/Events/AddEvent/AddEvent";
import Clients from "./screens/Owner/Nomenclature/ClientsMenu/Clients/Clients";
import EditClient from "./screens/Owner/Nomenclature/ClientsMenu/Clients/EditClient";
import moment from "moment";
import 'moment/locale/ro';
import 'moment/locale/en-in';
import * as localeRo from 'moment/locale/ro';
import * as localeEnIn from 'moment/locale/en-in';
import EditEvent from "./screens/Owner/Calendar/Events/EditEvent/EditEvent";
import Details from "./screens/Owner/Calendar/Events/EditEvent/Details/Details";
import Taste from "./screens/Owner/Calendar/Events/EditEvent/Taste/Taste";
import EventSheet from "./screens/Owner/Calendar/Events/EditEvent/EventSheet/EventSheet";
import FoodMenu from "./screens/Owner/Calendar/Events/EditEvent/FoodMenu/FoodMenu";
import DrinkMenu from "./screens/Owner/Calendar/Events/EditEvent/DrinkMenu/DrinkMenu";
import Staff from "./screens/Owner/Calendar/Events/EditEvent/Staff/Staff";
import Contracts from "./screens/Owner/Calendar/Events/EditEvent/Contracts/Contracts";
import Invoices from "./screens/Owner/Calendar/Events/EditEvent/Invoices/Invoices";
import Providers from "./screens/Owner/Nomenclature/Logistics/Providers/Providers";
import AddProvider from "./screens/Owner/Nomenclature/Logistics/Providers/AddProvider";
import EditProvider from "./screens/Owner/Nomenclature/Logistics/Providers/EditProvider";
import AddProduct from "./screens/Owner/Nomenclature/Logistics/Products/AddProduct";
import EditProduct from "./screens/Owner/Nomenclature/Logistics/Products/EditProduct";
import StaffCategories from "./screens/Owner/Nomenclature/Staff/StaffCategories";
import StaffMenu from "./screens/Owner/Nomenclature/Staff/Staff";
import AddCategory from "./screens/Owner/Nomenclature/Staff/AddCategory";
import EditCategory from "./screens/Owner/Nomenclature/Staff/EditCategory";
import AddStaff from "./screens/Owner/Nomenclature/Staff/AddStaff";
import EditStaff from "./screens/Owner/Nomenclature/Staff/EditStaff";
import StaffList from "./screens/Owner/Nomenclature/Staff/StaffList";
import ProductsList from "./screens/Owner/Nomenclature/Logistics/Products/ProductsList";
import Modal from "react-modal";
import Users from "./screens/Owner/Settings/Users/Users";
import AddUser from "./screens/Owner/Settings/Users/AddUser";
import EditUser from "./screens/Owner/Settings/Users/EditUser";
import ContractsMenu from "./screens/Owner/Finance/Contracts/ContractsMenu";
import ContractsTemplates from "./screens/Owner/Finance/Contracts/ContractsTemplates";
import AddTemplate from "./screens/Owner/Finance/Contracts/AddTemplate";
import EditTemplate from "./screens/Owner/Finance/Contracts/EditTemplate";
import AddContract from "./screens/Owner/Calendar/Events/EditEvent/Contracts/AddContract";
import EditContract from "./screens/Owner/Calendar/Events/EditEvent/Contracts/EditContract/EditContract";
import ContractsList from "./screens/Owner/Finance/Contracts/ContractsList";
import Client from "./screens/Owner/Calendar/Events/EditEvent/Client/Client";
import DashboardClient from "./screens/Client/Dashboard/Dashboard";
import ContractsClient from "./screens/Client/Contracts/ContractsClient";
import HotLink from "./screens/HotLink/HotLink";
import Menus from "./screens/Owner/Nomenclature/Menus/Menus";
import EditContractMenu from "./screens/Owner/Calendar/Events/EditEvent/Contracts/EditContract/EditContractMenu";
import Annexes from "./screens/Owner/Calendar/Events/EditEvent/Contracts/EditContract/Annexes";
import AddAnnex from "./screens/Owner/Calendar/Events/EditEvent/Contracts/EditContract/AddAnnex";
import EditAnnex from "./screens/Owner/Calendar/Events/EditEvent/Contracts/EditContract/EditAnnex";
import {registerLocale} from "react-datepicker";
import rom from "date-fns/locale/ro";
import * as eng from "date-fns/locale/en-IE";
import AddClient from "./screens/Owner/Nomenclature/ClientsMenu/Clients/AddClient";
import ViewContract from "./screens/Client/Contracts/ViewContract";
import Taxes from "./screens/Owner/Nomenclature/Taxes/Taxes";
import AddTax from "./screens/Owner/Nomenclature/Taxes/AddTax";
import EditTax from "./screens/Owner/Nomenclature/Taxes/EditTax";
import Vats from "./screens/Owner/Settings/Company/Vats/Vats";
import AddVat from "./screens/Owner/Settings/Company/Vats/AddVat";
import EditVat from "./screens/Owner/Settings/Company/Vats/EditVat";
import Ingredients from "./screens/Owner/Nomenclature/Menus/Ingredients/Ingredients";
import MenuProducts from "./screens/Owner/Nomenclature/Menus/MenuProducts/MenuProducts";
import AddMenuProduct from "./screens/Owner/Nomenclature/Menus/MenuProducts/AddMenuProduct";
import EditMenuProduct from "./screens/Owner/Nomenclature/Menus/MenuProducts/EditMenuProduct";
import AddIngredient from "./screens/Owner/Nomenclature/Menus/Ingredients/AddIngredient";
import EditIngredient from "./screens/Owner/Nomenclature/Menus/Ingredients/EditIngredient";
import Register from "./screens/Auth/Register/Register";
import VerifyEmail from "./screens/Auth/Register/VerifyEmail";
import Profitability from "./screens/Owner/Calendar/Events/EditEvent/Profitability/Profitability";
import AddInvoice from "./screens/Owner/Calendar/Events/EditEvent/Invoices/AddInvoice";
import InvoicesList from "./screens/Owner/Finance/Invoices/Invoices";
import InvoicesClient from "./screens/Client/Invoices/InvoicesClient";
import ClientsClient from "./screens/Client/Clients/Clients";
import EditClientClient from "./screens/Client/Clients/EditClient";
import Events from "./screens/Owner/Events/Events";
import EditInvoice from "./screens/Owner/Calendar/Events/EditEvent/Invoices/EditInvoice";
import Exports from "./screens/Owner/Exports/Exports";
import Imports from "./screens/Owner/Import/Imports";
import Notifications from "./screens/Owner/Settings/Notifications/Notifications/Notifications";
import AddNotification from "./screens/Owner/Settings/Notifications/Notifications/AddNotification";
import EditNotification from "./screens/Owner/Settings/Notifications/Notifications/EditNotification";
import NotificationsMenu from "./screens/Owner/Settings/Notifications/NotificationsMenu";
import CustomNotifications from "./screens/Owner/Settings/Notifications/CustomNotifications";
import AddCustomNotifications from "./screens/Owner/Settings/Notifications/AddCustomNotifications";
import EditCustomNotifications from "./screens/Owner/Settings/Notifications/EditCustomNotifications";
import AddCustomDrinkMenu from "./screens/Owner/Calendar/Events/EditEvent/DrinkMenu/AddCustomDrinkMenu";
import EditCustomDrinkMenu from "./screens/Owner/Calendar/Events/EditEvent/DrinkMenu/EditCustomDrinkMenu";
import AddCustomFoodMenu from "./screens/Owner/Calendar/Events/EditEvent/FoodMenu/AddCustomFoodMenu";
import EditCustomFoodMenu from "./screens/Owner/Calendar/Events/EditEvent/FoodMenu/EditCustomFoodMenu";
import EventsClient from "./screens/Client/Events/EventsClient";
import ViewEvent from "./screens/Client/Events/ViewEvent";
import ReportBugs from "./screens/Owner/ReportBugs/ReportBugs";
import PaymentDeadlines from "./screens/Owner/Calendar/Events/EditEvent/PaymentDeadlines/PaymentDeadlines";
import Activations from "./screens/Owner/Settings/Sponsors/Activations";
import ActivationsList from "./screens/Owner/Settings/Sponsors/ActivationsList";
import Sponsors from "./screens/Owner/Settings/Sponsors/Sponsors";
import AddActivation from "./screens/Owner/Settings/Sponsors/AddActivation";
import EditActivation from "./screens/Owner/Settings/Sponsors/EditActivation";
import AddSponsor from "./screens/Owner/Settings/Sponsors/AddSponsor";
import EditSponsor from "./screens/Owner/Settings/Sponsors/EditSponsor";
import LoginAdmin from "./screens/Admin/Login";
import Companies from "./screens/Admin/Companies/Companies";
import CompanyInfo from "./screens/Admin/Companies/CompanyInfo/CompanyInfo";
import UsersForCompany from "./screens/Admin/Companies/CompanyInfo/Users";
import LocationsForCompany from "./screens/Admin/Companies/CompanyInfo/Locations";
import ForgotPassword from "./screens/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./screens/Auth/ForgotPassword/ResetPassword";
import axios from "axios";
import {isJsonString} from "./utils/functions";
import Mentions from "./screens/Owner/Calendar/Events/EditEvent/Mentions/Mentions";
import Administrations from "./screens/Owner/Settings/Administrations/Administrations";
import AddAdministration from "./screens/Owner/Settings/Administrations/AddAdministration";
import EditAdministration from "./screens/Owner/Settings/Administrations/EditAdministration";
import CostsMenu from "./screens/Owner/Finance/Costs/CostsMenu";
import CostsList from "./screens/Owner/Finance/Costs/CostsList";
import AddCost from "./screens/Owner/Finance/Costs/AddCost";
import EditCost from "./screens/Owner/Finance/Costs/EditCost";
import CostsCategories from "./screens/Owner/Finance/Costs/CostsCategories";
import AddCostCategory from "./screens/Owner/Finance/Costs/AddCostCategory";
import EditCostCategory from "./screens/Owner/Finance/Costs/EditCostCategory";
import ProductReceptions from "./screens/Owner/Finance/ProductReceptions/ProductReceptions";
import AddProductReception from "./screens/Owner/Finance/ProductReceptions/AddProductReception";
import Stock from "./screens/Owner/Stock/Stock/Stock";
import ViewStock from "./screens/Owner/Stock/Stock/ViewStock";
import Transfers from "./screens/Owner/Stock/Transfers/Transfers";
import AddTransfer from "./screens/Owner/Stock/Transfers/AddTransfer";
import LossTickets from "./screens/Owner/Stock/LossTickets/LossTickets";
import AddLossTicket from "./screens/Owner/Stock/LossTickets/AddLossTicket";
import Offers from "./screens/Owner/Finance/Offers/Offers";
import AddOffer from "./screens/Owner/Finance/Offers/AddOffer";
import EditDocument from "./screens/Owner/Finance/Offers/EditDocument";
import Series from "./screens/Owner/Settings/Company/DocumentsSeries/Series";
import AddSeries from "./screens/Owner/Settings/Company/DocumentsSeries/AddSeries";
import EditSeries from "./screens/Owner/Settings/Company/DocumentsSeries/EditSeries";
import SectionUnavailable from "./screens/Owner/SectionUnavailable";
import ImportProducts from "./screens/Owner/Nomenclature/Menus/MenuProducts/ImportProducts";
import OffersForEvent from "./screens/Owner/Calendar/Events/EditEvent/Offers/Offers";
import OffersMenu from "./screens/Owner/Finance/Offers/OffersMenu";
import OffersTemplates from "./screens/Owner/Finance/Offers/OffersTemplates";
import AddOfferTemplate from "./screens/Owner/Finance/Offers/AddOfferTemplate";
import EditOfferTemplate from "./screens/Owner/Finance/Offers/EditOfferTemplate";
import LogisticsChecklist from "./screens/Owner/Nomenclature/LogisticsChecklist/LogisticsChecklist";
import LogisticsChecklistForEvent
    from "./screens/Owner/Calendar/Events/EditEvent/LogisticsChecklist/LogisticsChecklist";
import FeedbackForms from "./screens/Owner/Nomenclature/FeedbackForms/FeedbackForms";
import AddFeedbackForm from "./screens/Owner/Nomenclature/FeedbackForms/AddFeedbackForm";
import FeedbackForm from "./screens/Owner/Calendar/Events/EditEvent/FeedbackForm/FeedbackForm";
import CompleteFeedbackForm from "./screens/Client/FeedbackForms/CompleteFeedbackForm";
import FeedbackFormsClient from "./screens/Client/FeedbackForms/FeedbackFormsClient";
import Efactura from "./screens/Owner/Settings/Company/Efactura/Efactura";
import InvoicesMenu from "./screens/Owner/Finance/Invoices/InvoicesMenu";
import EfacturaInvoices from "./screens/Owner/Finance/Invoices/EfacturaInvoices";
import CompanyMenu from "./screens/Owner/Settings/Company/CompanyMenu";
import BankAccounts from "./screens/Owner/Settings/Company/BankAccounts/BankAccounts";
import AddBankAccount from "./screens/Owner/Settings/Company/BankAccounts/AddBankAccount";
import EditBankAccount from "./screens/Owner/Settings/Company/BankAccounts/EditBankAccount";
import OfferHistory from "./screens/Owner/Finance/Offers/OfferHistory";
import ClientsMenu from "./screens/Owner/Nomenclature/ClientsMenu/ClientsMenu";
import Agencies from "./screens/Owner/Nomenclature/ClientsMenu/Agencies/Agencies";
import AddAgency from "./screens/Owner/Nomenclature/ClientsMenu/Agencies/AddAgency";
import EditAgency from "./screens/Owner/Nomenclature/ClientsMenu/Agencies/EditAgency";
import Logistics from "./screens/Owner/Nomenclature/Logistics/Logistics";
import Tags from "./screens/Owner/Nomenclature/EventTags/Tags";
import AddTag from "./screens/Owner/Nomenclature/EventTags/AddTag";
import EditTag from "./screens/Owner/Nomenclature/EventTags/EditTag";
import ServicePackages from "./screens/Owner/Nomenclature/Logistics/ServicePackages/ServicePackages";
import AddServicePackage from "./screens/Owner/Nomenclature/Logistics/ServicePackages/AddServicePackage";
import EventLogistics from "./screens/Owner/Calendar/Events/EditEvent/Logistics/Logistics";
import ProductCategoriesList from "./screens/Owner/Nomenclature/Logistics/ProductCategories/ProductCategoriesList";
import AddProductCategory from "./screens/Owner/Nomenclature/Logistics/ProductCategories/AddProductCategory";
import Sources from "./screens/Owner/Nomenclature/EventSources/Sources";
import AddSource from "./screens/Owner/Nomenclature/EventSources/AddSource";
import EditSource from "./screens/Owner/Nomenclature/EventSources/EditSource";
import ExportProfitability from "./screens/Owner/Calendar/Events/EditEvent/Exports/ExportProfitability";
import ExportLogisticsChecklist from "./screens/Owner/Calendar/Events/EditEvent/Exports/ExportLogisticsChecklist";
import ExportStaff from "./screens/Owner/Calendar/Events/EditEvent/Exports/ExportStaff";
import ExportLogistics from "./screens/Owner/Calendar/Events/EditEvent/Exports/ExportLogistics";
import ExportDrinkMenu from "./screens/Owner/Calendar/Events/EditEvent/Exports/ExportDrinkMenu";
import ExportFoodMenu from "./screens/Owner/Calendar/Events/EditEvent/Exports/ExportFoodMenu";
import ExportEventSheet from "./screens/Owner/Calendar/Events/EditEvent/Exports/ExportEventSheet";
import AdminUsers from "./screens/Admin/Users/Users";
import EventsEventPlanner from "./screens/Owner/Events/EventsEventPlanner";
import EventTypes from "./screens/Owner/Settings/EventTypes/EventTypes";
import AddEventType from "./screens/Owner/Settings/EventTypes/AddEventType";
import EditEventType from "./screens/Owner/Settings/EventTypes/EditEventType";
import AddEventForEventPlanner from "./screens/Owner/Calendar/Events/AddEvent/AddEventForEventPlanner";
import Activities from "./screens/Owner/Nomenclature/Activities/Activities";
import EditActivity from "./screens/Owner/Nomenclature/Activities/EditActivity";
import Planner from "./screens/Owner/Calendar/Events/EditEvent/Planner/Planner";
import DetailsForEventPlanner from "./screens/Owner/Calendar/Events/EditEvent/Details/DetailsForEventPlanner";
import FoodMenus from "./screens/Owner/Nomenclature/Menus/FoodMenus/FoodMenus";
import AddFoodMenu from "./screens/Owner/Nomenclature/Menus/FoodMenus/AddFoodMenu";
import EditFoodMenu from "./screens/Owner/Nomenclature/Menus/FoodMenus/EditFoodMenu";
import ViewFoodMenu from "./screens/Owner/Nomenclature/Menus/FoodMenus/ViewFoodMenu";
import MenusForEventPlanner from "./screens/Owner/Calendar/Events/EditEvent/MenusForEventPlanner/MenusForEventPlanner";
import EditCustomFoodMenuForEventPlanner
    from "./screens/Owner/Calendar/Events/EditEvent/MenusForEventPlanner/EditCustomFoodMenuForEventPlanner";
import EditCustomDrinkMenuForEventPlanner
    from "./screens/Owner/Calendar/Events/EditEvent/MenusForEventPlanner/EditCustomDrinkMenuForEventPlanner";
import AddOfferForEventPlanner from "./screens/Owner/Finance/Offers/AddOfferForEventPlanner";
import SubscriptionExpired from "./screens/Owner/SubscriptionExpired/SubscriptionExpired";
import Subscription from "./screens/Admin/Companies/CompanyInfo/Subscription";
import AddContractPdf from "./screens/Owner/Calendar/Events/EditEvent/Contracts/AddContractPdf";
import AnnexesClient from "./screens/Client/Contracts/AnnexesClient";
import ViewAnnex from "./screens/Client/Contracts/ViewAnnex";
import Bonus from "./screens/Client/Bonus/Bonus";
import BonusHistoryForCompany from "./screens/Client/Bonus/BonusHistoryForCompany";
import Vouchers from "./screens/Client/Vouchers/Vouchers";
import VouchersOwner from "./screens/Owner/Vouchers/Vouchers";

Modal.setAppElement('body');

export default function App() {

    const companyType = localStorage.getItem('type')
    const isJwt = localStorage.getItem('jwt') !== null
    const role = localStorage.getItem('role') ?? null
    const access = JSON.parse(localStorage.getItem('access') ?? '{}')
    const subscription_expiration_date = localStorage.getItem('subscription_expiration_date')

    const [language, setLanguage] = useState('ro')

    const getLanguageFile = (lang) => {
        switch (lang) {
            case 'ro':
                moment.updateLocale('ro', localeRo);
                registerLocale('ro', rom);
                moment.updateLocale('ro', {
                    week: {
                        dow: 1, // Monday is the first day of the week.
                    }
                });
                return ro
            case 'en':
                moment.updateLocale('en', localeEnIn);
                registerLocale('en', eng);
                return ro
            default:
                return ro
        }
    }

    const changeLanguage = (lang) => {
        setLanguage(lang)
        localStorage.setItem('language', lang)
    }

    const languageContextValue = {
        language: language,
        setLanguage: changeLanguage
    };

    [window, document].forEach(
        el => el.addEventListener('message', (message) => {

            let data = message.data && isJsonString(message.data) && JSON.parse(message.data) ? JSON.parse(message.data) : false
            if (data) {
                // eslint-disable-next-line
                switch (data.action) {
                    case 'registerPushToken':
                        registerPushToken(data.token)
                        break
                }
            }
        }))

    const registerPushToken = async (token) => {
        let data = {
            token: token
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.post(process.env.REACT_APP_API + '/users/updatePushToken', data, auth)
    }

    return (
        <LanguageContext.Provider value={languageContextValue}>
            <IntlProvider messages={getLanguageFile(language)} locale={language}>
                <ToastContainer/>
                {
                    !isJwt ?
                        <BrowserRouter>
                            <Routes>
                                <Route exact path="/" element={<Login/>}/>
                                <Route exact path="/admin" element={<LoginAdmin/>}/>
                                <Route exact path="/register" element={<Register/>}/>
                                <Route exact path="/verify-email/:id" element={<VerifyEmail/>}/>
                                <Route exact path="/hl" element={<HotLink/>}/>
                                <Route exact path="/forgot-password" element={<ForgotPassword/>}/>
                                <Route exact path="/reset-password" element={<ResetPassword/>}/>
                            </Routes>
                        </BrowserRouter>
                        :
                        role && parseInt(role) === 4 ? // Role: Admin
                            <BrowserRouter>
                                <Routes>
                                    {/*<Route exact path="/" element={<Login/>}/>*/}
                                    <Route element={<Layout/>}>
                                        <Route exact path="/company" element={<Companies/>}/>
                                        <Route exact path="/users" element={<AdminUsers/>}/>
                                        <Route path="/company/:id" element={<CompanyInfo/>}>
                                            <Route exact path={"subscription"} element={<Subscription/>}/>
                                            <Route exact path={"users"} element={<UsersForCompany/>}/>
                                            <Route exact path={"locations"} element={<LocationsForCompany/>}/>
                                        </Route>
                                        <Route exact path={""} element={<Account/>}/>
                                    </Route>
                                </Routes>
                            </BrowserRouter>
                            :
                            role && parseInt(role) === 1 ? // Role: Owner
                                <BrowserRouter>
                                    <Routes>
                                        <Route exact path="/admin" element={<LoginAdmin/>}/>
                                        <Route exact path="/hl" element={<HotLink/>}/>
                                        {
                                            moment(subscription_expiration_date).unix() < moment().unix() ?
                                                <>
                                                    <Route exact path="/"
                                                           element={<SubscriptionExpired/>}/>
                                                    <Route exact path="/subscription-expired"
                                                           element={<SubscriptionExpired/>}/>
                                                </>
                                                :
                                                <Route element={<Layout/>}>
                                                    <Route exact path="/" element={<Dashboard/>}/>
                                                    <Route path="/calendar" element={<CalendarScreen/>}/>

                                                    {access && (access.events) && (
                                                        companyType === 'event_planner' ?
                                                            <Route path="/events/add"
                                                                   element={<AddEventForEventPlanner/>}/>
                                                            :
                                                            <Route path="/events/add" element={<AddEvent/>}/>
                                                    )}
                                                    {
                                                        access && (access.events) ?
                                                            <Route path="/events/:id" element={<EditEvent/>}>

                                                                {companyType === 'event_planner' ?
                                                                    <Route exact path={""}
                                                                           element={<DetailsForEventPlanner/>}/>
                                                                    :
                                                                    <Route exact path={""} element={<Details/>}/>}
                                                                <Route exact path={"taste"} element={<Taste/>}/>
                                                                <Route exact path={"event-sheet"}
                                                                       element={<EventSheet/>}/>

                                                                {companyType !== 'event_planner' ?
                                                                    <><Route exact path={"food-menu"}
                                                                             element={<FoodMenu/>}/>
                                                                        <Route exact
                                                                               path={"food-menu/add/:id_menu/:event_type"}
                                                                               element={<AddCustomFoodMenu/>}/>
                                                                        <Route exact
                                                                               path={"food-menu/edit/:id_menu/:event_type"}
                                                                               element={<EditCustomFoodMenu/>}/>
                                                                        <Route exact path={"drink-menu"}
                                                                               element={<DrinkMenu/>}/>
                                                                        <Route exact
                                                                               path={"drink-menu/add/:id_menu/:event_type"}
                                                                               element={<AddCustomDrinkMenu/>}/>
                                                                        <Route exact
                                                                               path={"drink-menu/edit/:id_menu/:event_type"}
                                                                               element={<EditCustomDrinkMenu/>}/></>
                                                                    :
                                                                    <><Route exact path={"menus"}
                                                                             element={<MenusForEventPlanner/>}/>
                                                                        <Route exact
                                                                               path={"menus/food/:id_menu/:id_menu_to_event/:qty"}
                                                                               element={
                                                                                   <EditCustomFoodMenuForEventPlanner/>}/>
                                                                        <Route exact
                                                                               path={"menus/drink/:id_menu/:id_menu_to_event/:qty"}
                                                                               element={
                                                                                   <EditCustomDrinkMenuForEventPlanner/>}/>
                                                                    </>}


                                                                <Route exact path={"logistics"}
                                                                       element={<EventLogistics/>}/>
                                                                <Route exact path={"staff"} element={<Staff/>}/>
                                                                <Route exact path={"logistics-checklist"}
                                                                       element={<LogisticsChecklistForEvent/>}/>
                                                                <Route exact path={"planner"} element={<Planner/>}/>
                                                                <Route exact path={"client"} element={<Client/>}/>
                                                                <Route exact path={"contracts"} element={<Contracts/>}/>
                                                                <Route exact path={"payment-deadlines"}
                                                                       element={<PaymentDeadlines/>}/>
                                                                <Route exact path={"contracts/add"} element={<AddContract/>}/>
                                                                <Route exact path={"contracts/add-pdf"} element={<AddContractPdf/>}/>
                                                                <Route exact path={"invoices"} element={<Invoices/>}/>
                                                                <Route exact path={"offers"}
                                                                       element={<OffersForEvent/>}/>
                                                                {companyType === 'event_planner' ?
                                                                    <Route exact path={"offers/:offer"}
                                                                           element={<AddOfferForEventPlanner/>}/>
                                                                    :
                                                                    <Route exact path={"offers/:offer"} element={<AddOffer/>}/>}
                                                                <Route exact path={"offers/document/:offer"}
                                                                       element={<EditDocument/>}/>
                                                                <Route exact path={"invoices/add"}
                                                                       element={<AddInvoice/>}/>
                                                                <Route exact path={"invoices/:invoice_id"}
                                                                       element={<EditInvoice/>}/>
                                                                <Route exact path={"profitability"}
                                                                       element={<Profitability/>}/>
                                                                <Route exact path={"mentions"} element={<Mentions/>}/>
                                                                <Route exact path={"feedback-form"}
                                                                       element={<FeedbackForm/>}/>

                                                                <Route exact path={"export/event-sheet"}
                                                                       element={<ExportEventSheet/>}/>
                                                                <Route exact path={"export/food-menu"}
                                                                       element={<ExportFoodMenu/>}/>
                                                                <Route exact path={"export/drink-menu"}
                                                                       element={<ExportDrinkMenu/>}/>
                                                                <Route exact path={"export/logistics"}
                                                                       element={<ExportLogistics/>}/>
                                                                <Route exact path={"export/staff"}
                                                                       element={<ExportStaff/>}/>
                                                                <Route exact path={"export/logistics-checklist"}
                                                                       element={<ExportLogisticsChecklist/>}/>
                                                                <Route exact path={"export/profitability"}
                                                                       element={<ExportProfitability/>}/>
                                                            </Route>
                                                            :
                                                            access && access.view_events &&
                                                            <Route path="/events/:id" element={<EditEvent/>}>
                                                                {companyType === 'event_planner' ?
                                                                    <Route exact path={""}
                                                                           element={<DetailsForEventPlanner/>}/>
                                                                    :
                                                                    <Route exact path={""} element={<Details/>}/>}
                                                                <Route exact path={"taste"} element={<Taste/>}/>
                                                                <Route exact path={"event-sheet"}
                                                                       element={<EventSheet/>}/>
                                                                {companyType !== 'event_planner' ?
                                                                    <>
                                                                        <Route exact path={"food-menu"}
                                                                               element={<FoodMenu/>}/>
                                                                        <Route exact path={"drink-menu"}
                                                                               element={<DrinkMenu/>}/></>
                                                                    :
                                                                    <Route exact path={"menus"}
                                                                           element={<MenusForEventPlanner/>}/>}


                                                                <Route exact path={"logistics"}
                                                                       element={<EventLogistics/>}/>
                                                                <Route exact path={"staff"} element={<Staff/>}/>
                                                                <Route exact path={"logistics-checklist"}
                                                                       element={<LogisticsChecklistForEvent/>}/>
                                                                <Route exact path={"client"} element={<Client/>}/>
                                                                <Route exact path={"contracts"} element={<Contracts/>}/>
                                                                <Route exact path={"payment-deadlines"}
                                                                       element={<PaymentDeadlines/>}/>
                                                                <Route exact path={"invoices"} element={<Invoices/>}/>
                                                                <Route exact path={"offers"}
                                                                       element={<OffersForEvent/>}/>
                                                                <Route exact path={"profitability"}
                                                                       element={<Profitability/>}/>
                                                                <Route exact path={"mentions"} element={<Mentions/>}/>
                                                                <Route exact path={"feedback-form"}
                                                                       element={<FeedbackForm/>}/>


                                                                <Route exact path={"export/event-sheet"}
                                                                       element={<ExportEventSheet/>}/>
                                                                <Route exact path={"export/food-menu"}
                                                                       element={<ExportFoodMenu/>}/>
                                                                <Route exact path={"export/drink-menu"}
                                                                       element={<ExportDrinkMenu/>}/>
                                                                <Route exact path={"export/logistics"}
                                                                       element={<ExportLogistics/>}/>
                                                                <Route exact path={"export/staff"}
                                                                       element={<ExportStaff/>}/>
                                                                <Route exact path={"export/logistics-checklist"}
                                                                       element={<ExportLogisticsChecklist/>}/>
                                                                <Route exact path={"planner"} element={<Planner/>}/>
                                                                <Route exact path={"export/profitability"}
                                                                       element={<ExportProfitability/>}/>
                                                            </Route>
                                                    }

                                                    {access && (access.events) && (
                                                        companyType === 'event_planner' ?
                                                            <Route path="/events" element={<EventsEventPlanner/>}/>
                                                            :
                                                            <Route path="/events" element={<Events/>}/>)}

                                                    {access && (access.menus) &&
                                                        <Route path="/menus" element={<Menus/>}>

                                                            <Route path="ingredients" element={<Ingredients/>}/>
                                                            <Route exact path={"ingredients/add"}
                                                                   element={<AddIngredient/>}/>
                                                            <Route exact path={"ingredients/:id"}
                                                                   element={<EditIngredient/>}/>

                                                            <Route path="menu-products" element={<MenuProducts/>}/>
                                                            <Route exact path={"menu-products/import"}
                                                                   element={<ImportProducts/>}/>
                                                            <Route exact path={"menu-products/add"}
                                                                   element={<AddMenuProduct/>}/>
                                                            <Route exact path={"menu-products/:id"}
                                                                   element={<EditMenuProduct/>}/>


                                                            <Route path="food-menus" element={<FoodMenus/>}/>

                                                            <Route exact path={"food-menus/add/:id"}
                                                                   element={<AddFoodMenu/>}/>
                                                            <Route exact path={"food-menus/:id"}
                                                                   element={<EditFoodMenu/>}/>
                                                            <Route exact path={"food-menus/view/:id"}
                                                                   element={<ViewFoodMenu/>}/>


                                                            <Route exact path="drink-menus" element={<DrinksMenus/>}/>
                                                            <Route exact path={"drink-menus/add/:id"}
                                                                   element={<AddDrinksMenu/>}/>
                                                            <Route exact path={"drink-menus/:id"}
                                                                   element={<EditDrinksMenu/>}/>
                                                            <Route exact path={"drink-menus/view/:id"}
                                                                   element={<ViewDrinkMenu/>}/>
                                                        </Route>}

                                                    {access && (access.logistics) &&
                                                        <Route path="/logistics" element={<Logistics/>}>
                                                            <Route exact path={"products"} element={<ProductsList/>}/>
                                                            <Route exact path={"products/add"} element={<AddProduct/>}/>
                                                            <Route exact path={"products/:id"}
                                                                   element={<EditProduct/>}/>

                                                            <Route exact path={"categories"}
                                                                   element={<ProductCategoriesList/>}/>
                                                            <Route exact path={"categories/:id"}
                                                                   element={<AddProductCategory/>}/>
                                                            <Route exact path={"packages"}
                                                                   element={<ServicePackages/>}/>
                                                            <Route exact path={"packages/:id"}
                                                                   element={<AddServicePackage/>}/>

                                                            <Route exact path={"providers"} element={<Providers/>}/>
                                                            <Route exact path={"providers/add"}
                                                                   element={<AddProvider/>}/>
                                                            <Route exact path={"providers/:id"}
                                                                   element={<EditProvider/>}/>
                                                        </Route>}

                                                    {access && (access.logistics_checklist) &&
                                                        <Route path={'/logistics-checklist'}
                                                               element={<LogisticsChecklist/>}/>}

                                                    {access && (access.activities) && companyType === 'event_planner' &&
                                                        <Route path="/activities" element={<Activities/>}/>}
                                                    {access && (access.activities) && companyType === 'event_planner' &&
                                                        <Route path="/activities/:id" element={<EditActivity/>}/>}

                                                    {access && (access.clients) &&
                                                        <Route path="/clients" element={<ClientsMenu/>}>
                                                            <Route path="list" element={<Clients/>}/>
                                                            <Route exact path={"list/add"} element={<AddClient/>}/>
                                                            <Route exact path={"list/:id"} element={<EditClient/>}/>
                                                            <Route path="agencies" element={<Agencies/>}/>
                                                            <Route exact path={"agencies/add"} element={<AddAgency/>}/>
                                                            <Route exact path={"agencies/:id"} element={<EditAgency/>}/>
                                                        </Route>}

                                                    {access && (access.staff) &&
                                                        <Route path="/staff" element={<StaffMenu/>}>
                                                            <Route path="users" element={<StaffList/>}/>
                                                            <Route exact path={"users/add"} element={<AddStaff/>}/>
                                                            <Route exact path={"users/:id"} element={<EditStaff/>}/>
                                                        </Route>}
                                                    {access && (access.staff) &&
                                                        <Route path="/staff/staff-categories" element={<StaffMenu/>}>
                                                            <Route path="" element={<StaffCategories/>}/>
                                                            <Route exact path={"add"} element={<AddCategory/>}/>
                                                            <Route exact path={":id"} element={<EditCategory/>}/>
                                                        </Route>}


                                                    {access && (access.taxes) &&
                                                        <Route exact path={"/taxes"} element={<Taxes/>}/>}
                                                    {access && (access.taxes) &&
                                                        <Route exact path={"/taxes/add"} element={<AddTax/>}/>}
                                                    {access && (access.taxes) &&
                                                        <Route exact path={"/taxes/:id"} element={<EditTax/>}/>}


                                                    {access && (access.event_sources) &&
                                                        <Route exact path={"/sources"} element={<Sources/>}/>}
                                                    {access && (access.event_sources) &&
                                                        <Route exact path={"/sources/add"} element={<AddSource/>}/>}
                                                    {access && (access.event_sources) &&
                                                        <Route exact path={"/sources/:id"} element={<EditSource/>}/>}
                                                    {access && (access.event_tags) &&
                                                        <Route exact path={"/tags"} element={<Tags/>}/>}
                                                    {access && (access.event_tags) &&
                                                        <Route exact path={"/tags/add"} element={<AddTag/>}/>}
                                                    {access && (access.event_tags) &&
                                                        <Route exact path={"/tags/:id"} element={<EditTag/>}/>}

                                                    {access && (access.feedback_forms) &&
                                                        <Route exact path={"/feedback-forms"}
                                                               element={<FeedbackForms/>}/>}
                                                    {access && (access.feedback_forms) &&
                                                        <Route exact path={"/feedback-forms/:id"}
                                                               element={<AddFeedbackForm/>}/>}

                                                    {access && (access.costs) &&
                                                        <Route path="/costs" element={<CostsMenu/>}>
                                                            <Route path="list" element={<CostsList/>}/>
                                                            <Route exact path={"list/add"} element={<AddCost/>}/>
                                                            <Route exact path={"list/:id"} element={<EditCost/>}/>
                                                        </Route>}
                                                    {access && (access.costs) &&
                                                        <Route path="/costs/costs-categories" element={<CostsMenu/>}>
                                                            <Route path="" element={<CostsCategories/>}/>
                                                            <Route exact path={"add"} element={<AddCostCategory/>}/>
                                                            <Route exact path={":id"} element={<EditCostCategory/>}/>
                                                        </Route>}

                                                    {access && (access.offers) &&
                                                        <Route path="/offers" element={<OffersMenu/>}>
                                                            <Route exact path={"list"} element={<Offers/>}/>
                                                            {companyType === 'event_planner' ?
                                                                <Route exact path={"list/:id"}
                                                                       element={<AddOfferForEventPlanner/>}/>
                                                                :
                                                                <Route exact path={"list/:id"} element={<AddOffer/>}/>}
                                                            <Route exact path={"list/document/:id"}
                                                                   element={<EditDocument/>}/>
                                                            <Route exact path={"templates"}
                                                                   element={<OffersTemplates/>}/>
                                                            <Route exact path={"templates/add"}
                                                                   element={<AddOfferTemplate/>}/>
                                                            <Route exact path={"templates/:id"}
                                                                   element={<EditOfferTemplate/>}/>
                                                        </Route>}
                                                    {access && (access.offers) &&
                                                        <Route exact path={"/offers/list/history/:id"}
                                                               element={<OfferHistory/>}/>}

                                                    <Route path="/vouchers" element={<VouchersOwner/>}/>
                                                    {access && (access.invoices) &&
                                                        <Route path="/invoices" element={<InvoicesMenu/>}>
                                                            <Route path="" element={<InvoicesList/>}/>
                                                            <Route path="efactura" element={<EfacturaInvoices/>}/>
                                                            <Route exact path={":invoice_id"}
                                                                   element={<EditInvoice/>}/>
                                                        </Route>}

                                                    {access && (access.contracts) &&
                                                        <Route path="/contracts" element={<ContractsMenu/>}>
                                                            <Route exact path={""} element={<ContractsList/>}/>
                                                            {/*<Route exact path={":id"} element={<EditContract/>}/>*/}
                                                            <Route exact path={"templates"}
                                                                   element={<ContractsTemplates/>}/>
                                                            <Route exact path={"templates/add"}
                                                                   element={<AddTemplate/>}/>
                                                            <Route exact path={"templates/:id"}
                                                                   element={<EditTemplate/>}/>
                                                        </Route>}

                                                    {access && (access.contracts) &&
                                                        <Route exact path={"contracts/:id"}
                                                               element={<EditContractMenu/>}>
                                                            <Route exact path={""} element={<EditContract/>}/>
                                                            <Route exact path={"annexes"} element={<Annexes/>}/>
                                                            <Route exact path={"annexes/add"} element={<AddAnnex/>}/>
                                                            <Route exact path={"annexes/:annex"}
                                                                   element={<EditAnnex/>}/>
                                                        </Route>}


                                                    {access && (access.export) &&
                                                        <Route path="/export" element={<Exports/>}/>}
                                                    {access && (access.import) &&
                                                        <Route path="/import" element={<Imports/>}/>}
                                                    <Route exact path={"/settings"} element={<Account/>}/>

                                                    {access && (access.companies) &&
                                                        <Route path="/company" element={<CompanyMenu/>}>
                                                            <Route exact path={"edit"} element={<Company/>}/>
                                                            <Route exact path={"e-factura"} element={<Efactura/>}/>

                                                            <Route exact path={"vats"} element={<Vats/>}/>
                                                            <Route exact path={"vats/add"} element={<AddVat/>}/>
                                                            <Route exact path={"vats/:id"} element={<EditVat/>}/>
                                                            <Route exact path={"documents-series"} element={<Series/>}/>
                                                            <Route exact path={"documents-series/add"}
                                                                   element={<AddSeries/>}/>
                                                            <Route exact path={"documents-series/:id"}
                                                                   element={<EditSeries/>}/>
                                                            <Route exact path={"bank-accounts"}
                                                                   element={<BankAccounts/>}/>
                                                            <Route exact path={"bank-accounts/add"}
                                                                   element={<AddBankAccount/>}/>
                                                            <Route exact path={"bank-accounts/:id"}
                                                                   element={<EditBankAccount/>}/>
                                                        </Route>}

                                                    {access && (access.users) &&
                                                        <Route exact path={"/users"} element={<Users/>}/>}
                                                    {access && (access.users) &&
                                                        <Route exact path={"/users/add"} element={<AddUser/>}/>}
                                                    {access && (access.users) &&
                                                        <Route exact path={"/users/:id"} element={<EditUser/>}/>}
                                                    {access && (access.notifications) &&
                                                        <Route path="/notifications" element={<NotificationsMenu/>}>
                                                            <Route path="configuration" element={<Notifications/>}/>
                                                            <Route exact path={"configuration/add"}
                                                                   element={<AddNotification/>}/>
                                                            <Route exact path={"configuration/:id"}
                                                                   element={<EditNotification/>}/>
                                                        </Route>}
                                                    {access && (access.notifications) &&
                                                        <Route path="/notifications" element={<NotificationsMenu/>}>
                                                            <Route path="custom" element={<CustomNotifications/>}/>
                                                            <Route exact path={"custom/add"}
                                                                   element={<AddCustomNotifications/>}/>
                                                            <Route exact path={"custom/:id"}
                                                                   element={<EditCustomNotifications/>}/>
                                                        </Route>}
                                                    {access && (access.event_types) &&
                                                        <Route exact path={"/event-types"} element={<EventTypes/>}/>}
                                                    {access && (access.event_types) &&
                                                        <Route exact path={"/event-types/add"}
                                                               element={<AddEventType/>}/>}
                                                    {access && (access.event_types) &&
                                                        <Route exact path={"/event-types/:id"}
                                                               element={<EditEventType/>}/>}


                                                    {access && (access.locations) &&
                                                        <Route exact path={"/locations"} element={<Locations/>}/>}
                                                    {access && (access.locations) &&
                                                        <Route exact path={"/locations/add"} element={<AddLocation/>}/>}
                                                    {access && (access.locations) &&
                                                        <Route exact path={"/locations/:id"}
                                                               element={<EditLocation/>}/>}


                                                    <Route exact path={"bugs"} element={<ReportBugs/>}/>
                                                    <Route exact path={"no-access"} element={<SectionUnavailable/>}/>


                                                    {/*UNUSED*/}
                                                    <Route path="/product-receptions" element={<ProductReceptions/>}/>
                                                    <Route exact path={"/product-receptions/:id"}
                                                           element={<AddProductReception/>}/>
                                                    <Route path="/stock" element={<Stock/>}/>
                                                    <Route path="/stock/:id" element={<ViewStock/>}/>
                                                    <Route path="/transfers" element={<Transfers/>}/>
                                                    <Route exact path={"/transfers/:id"} element={<AddTransfer/>}/>
                                                    <Route path="/loss-tickets" element={<LossTickets/>}/>
                                                    <Route exact path={"/loss-tickets/:id"} element={<AddLossTicket/>}/>
                                                    <Route path="/activations" element={<Activations/>}>
                                                        <Route exact path={"items"} element={<ActivationsList/>}/>
                                                        <Route exact path={"items/add"} element={<AddActivation/>}/>
                                                        <Route exact path={"items/:id"} element={<EditActivation/>}/>
                                                    </Route>
                                                    <Route path="/activations/sponsors" element={<Activations/>}>
                                                        <Route exact path={""} element={<Sponsors/>}/>
                                                        <Route exact path={"add"} element={<AddSponsor/>}/>
                                                        <Route exact path={":id"} element={<EditSponsor/>}/>
                                                    </Route>

                                                    <Route exact path={"/administrations"}
                                                           element={<Administrations/>}/>
                                                    <Route exact path={"/administrations/add"}
                                                           element={<AddAdministration/>}/>
                                                    <Route exact path={"/administrations/:id"}
                                                           element={<EditAdministration/>}/>
                                                </Route>

                                        }
                                    </Routes>
                                </BrowserRouter>
                                :
                                <BrowserRouter>
                                    <Routes>
                                        <Route exact path="/admin" element={<LoginAdmin/>}/>
                                        <Route exact path="/hl" element={<HotLink/>}/>
                                        <Route element={<Layout/>}>
                                            <Route exact path="/" element={<DashboardClient/>}/>

                                            <Route path="/events">
                                                <Route exact path={""} element={<EventsClient/>}/>
                                                <Route exact path={":id"} element={<ViewEvent/>}/>
                                            </Route>

                                            <Route path="/contracts">
                                                <Route exact path={""} element={<ContractsClient/>}/>
                                                <Route exact path={":id/annexes"} element={<AnnexesClient/>}/>
                                                <Route exact path={":id/annexes/:id_annex"} element={<ViewAnnex/>}/>
                                                <Route exact path={":id"} element={<ViewContract/>}/>
                                            </Route>
                                            <Route path="/feedback-forms">
                                                <Route exact path={""} element={<FeedbackFormsClient/>}/>
                                                <Route exact path={":id"} element={<CompleteFeedbackForm/>}/>
                                            </Route>

                                            <Route path="/invoices" element={<InvoicesClient/>}/>
                                            <Route path="/bonus" element={<Bonus/>}/>
                                            <Route path="/vouchers" element={<Vouchers/>}/>
                                            <Route path="/bonus/:id" element={<BonusHistoryForCompany/>}/>
                                            <Route path="/clients" element={<ClientsClient/>}/>
                                            <Route path="/clients/:id" element={<EditClientClient/>}/>
                                            <Route exact path={"settings"} element={<Account/>}/>
                                            <Route exact path={"bugs"} element={<ReportBugs/>}/>
                                        </Route>
                                    </Routes>
                                </BrowserRouter>
                }
            </IntlProvider>
        </LanguageContext.Provider>
    )
}


