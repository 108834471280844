import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import {useNavigate, useParams} from "react-router-dom";
import ModalGenerateVoucher from "../../../components/ModalGenerateVoucher/ModalGenerateVoucher";
import Button from "../../../components/elements/Button/Button";

export default function BonusHistoryForCompany() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()

    let params = useParams();
    const [bonuses, setBonuses] = useState([])
    const [total, setTotal] = useState(0)
    const [company, setCompany] = useState('')
    const [modalGenerateVoucherIsOpen, setModalGenerateVoucherIsOpen] = useState(false)

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/bonuses/for-client/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setBonuses(response.data.bonuses ?? [])
                setTotal(response.data.total ?? 0)
                setCompany(response.data.company ?? '')
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.bonus'})}</h1>
                </div>
                <div className={"col-12 pb-4"}>
                    <div className="card">
                        <div className={"header"}>
                            <div className={"row"}>
                                <div className={"col-md-6 col-sm-12"}>
                                    <p>{intl.formatMessage({id: 'general.sidebar.bonus'})} {company}</p>
                                </div>
                                <div className={"col-md-6 col-sm-12"}>
                                    <p style={{float: "right"}}>{parseFloat(total).toFixed(2)} Lei</p>
                                </div>
                            </div>
                        </div>
                        {
                            bonuses.length === 0 ?
                                <div className={"content"}>

                                    <div className={"alert alert-warning"}>{intl.formatMessage({id: 'bonuses.alert_no_bonuses_found'})}</div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table  events-client mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div className={"col-2 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>
                                                <div className={"col-3 table-td"}>{intl.formatMessage({id: 'general.company'})}</div>
                                                <div className={"col-3 table-td"}>{intl.formatMessage({id: 'general.event'})}</div>
                                                <div className={"col-2 table-td"}>{intl.formatMessage({id: 'general.sidebar.bonus'})}</div>
                                                <div className={"col-2 table-td"}>{intl.formatMessage({id: 'general.bonus_left'})}</div>
                                            </div>
                                        </div>

                                        <div className={"table-body"}>
                                            {
                                                bonuses.map((bonus, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                {i+1}
                                                            </div>
                                                            <div
                                                                className={"col-3 table-td"}>
                                                                {bonus.company.title}
                                                            </div>
                                                            <div
                                                                className={"col-3 table-td"}>
                                                                {bonus.event.title}
                                                            </div>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                {parseFloat(bonus.value).toFixed(2)}
                                                            </div>
                                                            <div className={"col-2 table-td"} style={{cursor: "pointer"}}
                                                                 onClick={() => navigate('/bonus/' + bonus.company.id)}
                                                            >
                                                                {parseFloat(bonus.change).toFixed(2)}
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        {total > 0 && bonuses.length > 0 &&<div className={"footer d-flex justify-content-center"}>
                            <div className={"col-12 table-td"}>
                                <Button
                                    className={"btn-primary mt-2 ms-auto"}
                                    style={{float: 'right'}}
                                    value={intl.formatMessage({id: 'general.generate_voucher'})}
                                    type={"button"}
                                    onClick={() => {
                                        setModalGenerateVoucherIsOpen(true)
                                    }}
                                />
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

            <ModalGenerateVoucher modalIsOpen={modalGenerateVoucherIsOpen} setModalIsOpen={setModalGenerateVoucherIsOpen}
                                  company={bonuses[0].company.id} total={total}/>
        </div>

    );
}

