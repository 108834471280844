import {useIntl} from "react-intl";
import React, {useState} from "react";
import Selector from "../elements/Selector/Selector";
import Button from "../elements/Button/Button";
import Input from "../elements/Input/Input";

export default function Item(
    {
        key,
        item,
        setItem,
        disabledDelete,
        onDelete,
        vatsOptions

    }
) {
    const intl = useIntl()
    const [editLine, setEditLine] = useState(false)
    const [label, setLabel] = useState(item.label)
    const [qty, setQty] = useState(item.qty)
    const [price, setPrice] = useState(item.price)
    const [vat, setVat] = useState(item.vat)


    return (

        editLine ?
            <tr key={key}>
                <td>
                    <Input
                        className={"form-input"}
                        type="text"
                        style={{textAlign: "left", width: '90%', maxWidth: "unset", marginLeft: 0}}
                        initValue={label}
                        onChange={(value) => {
                            setLabel(value)
                        }}
                    />
                </td>
                <td className={"right-aligned-cell form-control" + (qty ? '' : ' error')}
                    style={{display: "table-cell"}}>
                    <input
                        className={"d-flex form-input"}
                        type="number"
                        value={qty}
                        step={1}
                        onChange={(e) => setQty(e.target.value)}
                    />
                </td>
                <td className={"right-aligned-cell form-control" + (price ? '' : ' error')}
                style={{display: "table-cell"}}>
                    <input
                        className={"d-flex form-input"}
                        type="number"
                        value={parseFloat(price)}
                        step={0.01}
                        onChange={(e) => setPrice(e.target.value)}
                    /></td>
                <td className={"right-aligned-cell "}>
                    <div className={"float-right"}>
                        <Selector
                            options={vatsOptions}
                            value={vat}
                            onChange={(option) => setVat(option.value)}
                            isOptionDisabled={(option) => option.disabled}
                        />
                    </div>
                </td>
                <td className={"right-aligned-cell"}><strong>{parseFloat(qty * price).toFixed(2)}</strong></td>
                <td className={"right-aligned-cell delete"}>
                    <Button
                        className={"btn-light btn-edit"}
                        tooltip={intl.formatMessage({id: 'general.buttons.save'})}
                        icon={"fa-solid fa-floppy-disk"}
                        type={'button'}
                        onClick={() => {
                            if (qty && price) {
                                setItem(label, qty, price, vat)
                                setEditLine(false)
                            }

                        }}
                    />

                </td>
            </tr>
            :
            <tr key={key}>
                <td>{label}</td>
                <td className={"right-aligned-cell"}>{qty}</td>
                <td className={"right-aligned-cell"}>{parseFloat(price)}</td>
                <td className={"right-aligned-cell "}>
                    {vatsOptions.find(item => item.value === vat) ? vatsOptions.find(item => item.value === vat).label : 0}
                </td>
                <td className={"right-aligned-cell"}><strong>{parseFloat(qty * price).toFixed(2)}</strong></td>
                <td className={"right-aligned-cell delete"}>
                    <div className={"d-flex"}>
                        <Button
                            className={"btn-light btn-edit"}
                            tooltip={intl.formatMessage({id: 'general.edit'})}
                            icon={"fa-solid fa-pen"}
                            type={'button'}

                            onClick={() => setEditLine(true)}
                        />
                        <Button
                            className={"btn-light btn-delete"}
                            tooltip={intl.formatMessage({id: 'general.delete'})}
                            icon={"fa-solid fa-trash"}
                            type={'button'}
                            // loading={loadingDelete[i] ?? false}
                            disabled={disabledDelete}
                            onClick={() => onDelete()}
                        />
                    </div>
                </td>
            </tr>

    );
}

