import {useIntl} from "react-intl";
import React, {useState} from "react";
import Item from "./Item";
import {useOutletContext} from "react-router-dom";

export default function LineItems(
    {
        vatsOptions,
        total,
        setTotal,
        items,
        setItems = () => {
        },
    }
) {
    const intl = useIntl()
    const {context} = useOutletContext()
    const [loadingDelete, setLoadingDelete] = useState([])
    const setItem = (index, item, label, qty, price, vat) => {

        let newItems = [...items]
        newItems[index] = {...item}
        newItems[index].label = label
        newItems[index].qty = qty
        newItems[index].price = price
        newItems[index].vat = vat

        let totalTemp = 0
        for (let i=0; i < newItems.length; i++){
            totalTemp += parseFloat(parseFloat(parseFloat(newItems[i].price) * parseFloat(newItems[i].qty)).toFixed(2))
        }


        setTotal(totalTemp)
        context.setContentUpdated(true)
        setItems(newItems)

    }
    const deleteItem = (item) => {
        let newItems = [...items]
        let index = newItems.indexOf(item)
        if (index > -1) {
            context.setContentUpdated(true)
            setTotal(parseFloat(total - item.qty * item.price))
            newItems.splice(index, 1);
            setItems(newItems)
        }
        setLoadingDelete([])
    }
    return (

        <table className={"invoice-table-container"}>
            <thead>
            <tr className={"heading"}>
                <th className={"description"}>{intl.formatMessage({id: 'invoices.description'})}</th>
                <th className={"quantity"}>{intl.formatMessage({id: 'general.qty'})}</th>
                <th className={"price"}>{intl.formatMessage({id: 'general.price'})}</th>
                <th className={"price"}>{intl.formatMessage({id: 'general.vat'})}</th>
                <th className={"subtotal right-aligned-cell"}>{intl.formatMessage({id: 'invoices.subtotal'})}</th>
                <th className={"delete"}></th>
            </tr>
            </thead>
            <tbody>
            {items && items.map((item, i) => (
                <Item
                    vatsOptions={vatsOptions}
                    key={item.label + i}
                    item={item}
                    setItem={(label, qty, price, vat) => setItem(i, item, label, qty, price, vat)}
                    disabledDelete={loadingDelete[i] ?? false}
                    onDelete={() => {
                        let load = []
                        load[i] = true
                        setLoadingDelete(load)
                        deleteItem(item)
                    }}
                />
            ))}
            </tbody>
        </table>
    );
}

