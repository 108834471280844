import React, {useEffect, useState} from "react";
import LoadingComp from "../../../../../../../components/elements/LoadingComp/LoadingComp";
import axios from "axios";
import errorParser from "../../../../../../../components/ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import '../Contracts.scss';
import Button from "../../../../../../../components/elements/Button/Button";
import moment from "moment";
import {toast} from "react-toastify";

export default function Annexes() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    let params = useParams();
    const {context} = useOutletContext()

    const [loadingDownload, setLoadingDownload] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingDelete, setLoadingDelete] = useState([])
    const [annexes, setAnnexes] = useState([])
    const [loadingSend, setLoadingSend] = useState([])
    const [user, setUser] = useState(null)
    const query = new URLSearchParams(window.location.search);

    useEffect(() => {
        getAnnexes()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getAnnexes = async () => {
        axios.get(process.env.REACT_APP_API + '/annexes/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setAnnexes(response.data.annexes)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUser(response.data ?? [])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const deleteAnnex = async (id) => {
        let temp = []
        temp[id] = true
        setLoadingDelete(temp)
        axios.delete(process.env.REACT_APP_API + '/annexes/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                getAnnexes()
                setLoadingDelete([])
            })
            .catch(err => {
                setLoadingDelete([])
                errorParser(err, intl)
            })
    }

    const download = async (id, contract) => {
        let load = [];
        load[id] = true;

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: process.env.REACT_APP_API + '/annexes/download/' + id + '/' + user.id + '/' + contract.id,
            }))

        } else {
            setLoadingDownload(load)
            axios.get(process.env.REACT_APP_API + '/annexes/download/' + id + '/' + user.id + '/' + contract.id, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/pdf',
                }
            }).then((response) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    response.data,
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'annex-' + id + '.pdf',
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);


                setLoadingDownload([])
            })
                .catch(err => {
                    errorParser(err, intl)
                })
        }
    }

    const sendToClient = async (id) => {

        let load = [];
        load[id] = true;
        setLoadingSend(load)

        await axios.get(process.env.REACT_APP_API + '/annexes/send-to-client/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                getAnnexes()
                setLoadingSend([])
            })
            .catch(err => {
                errorParser(err, intl)
                setLoadingSend([])
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header"}>
                <div className={"row"}>
                    <div className={"col-xl-6 col-sm-12 "}>
                        <p> {intl.formatMessage({id: 'events.contracts.annexes'})}</p>
                    </div>
                    <div className={"col-xl-6 col-sm-12 d-flex"}>
                        <Button
                            className={"btn-primary ms-auto "}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            onClick={() => navigate('/contracts/' + params.id + '/annexes/add?back=' + query.get('back'))}
                        />
                    </div>
                </div>

            </div>


            {
                annexes.length === 0 ?
                    <div className={"content"}>

                        <div className={"alert alert-warning"}>{intl.formatMessage({id: 'contracts.alert_no_annexes_found'})}</div>
                    </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div
                                        className={"col-2 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>

                                    <div
                                        className={"col-4 table-td"}>{intl.formatMessage({id: 'events.contracts.date'})}</div>
                                    <div className={"col-6 table-td"}></div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    annexes.map((annex, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-2 table-td"}>
                                                    {annex.number}
                                                </div>
                                                <div
                                                    className={"col-4 table-td"}>
                                                    {moment(annex.date).format('Do MMMM YYYY')}
                                                </div>
                                                <div className={"col-6 table-td "}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-download"}
                                                        whiteLoader={false}
                                                        loading={loadingDownload[annex.id] ?? false}
                                                        disabled={loadingDownload[annex.id] ?? false}
                                                        tooltip={intl.formatMessage({id: 'general.buttons.download'})}
                                                        onClick={() => {
                                                            download(annex.id, annex.contract)
                                                        }}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-edit"}
                                                        icon={"fa-solid fa-pen"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        onClick={() => navigate('/contracts/' + params.id + '/annexes/' + annex.id + '?back=' + query.get('back'))}
                                                    />
                                                   <Button
                                                        className={"btn-light btn-delete"}
                                                        icon={"fa-solid fa-trash"}
                                                        tooltip={intl.formatMessage({id: 'general.buttons.delete'})}
                                                        whiteLoader={false}
                                                        disabled={loadingDelete[annex.id] ?? false}
                                                        loading={loadingDelete[annex.id] ?? false}
                                                        onClick={() => deleteAnnex(annex.id)}
                                                    />
                                                   <div className={"d-grid"}>
                                                        <Button
                                                            disabled={annex.contract.status === 'new' || loadingSend[annex.id]}
                                                            value={intl.formatMessage({id: 'general.buttons.send_to_client'})}
                                                            loading={loadingSend[annex.id] ?? false}
                                                            className={"btn-primary " + (annex.contract.status === 'new' ? 'disabled' : '')}
                                                            onClick={() => sendToClient(annex.id, 'send')}
                                                        />
                                                        {
                                                            annex.email_sent_at &&
                                                            <div className={"email-sent-at"}>
                                                                {intl.formatMessage({id: 'events.email_sent_at'})} {moment(annex.email_sent_at).format('Do/MM/YY H:mm')}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer d-flex justify-content-center"}>

                {
                    window.alityAppView &&
                    <div className={"col-12"}>
                        <Button
                            className={"btn-light mt-2"}
                            icon={"fa-solid fa-chevron-left"}
                            value={intl.formatMessage({id: 'general.buttons.back'})}
                            onClick={() => {

                                context.setLeavePage(true)
                                context.setNextLink('/calendar')
                            }}
                        />
                    </div>
                }
            </div>


        </div>
    );
}

