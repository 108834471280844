import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import Paginate from "../../../components/elements/Paginate/Paginate";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import moment from "moment";
import {useNavigate} from "react-router-dom";

export default function ContractsClient() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()

    const [contracts, setContracts] = useState([])

    const [loading, setLoading] = useState(true)
    const [loadingDownload, setLoadingDownload] = useState([])
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [user, setUser] = useState(null)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/contracts/client/' + page, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setContracts(response.data.contracts ?? [])
                setTotal(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const download = async (id, company) => {
        let load = [];
        load[id] = true;

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: process.env.REACT_APP_API + '/contracts/download/' + id + '/' + user.id + '/' + company.id,
            }))

        } else {
            setLoadingDownload(load)
            axios.get(process.env.REACT_APP_API + '/contracts/download/' + id + '/' + user.id + '/' + company.id, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/pdf',
                }
            }).then((response) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    response.data,
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'contract-' + id + '.pdf',
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);


                setLoadingDownload([])
            })
                .catch(err => {
                    errorParser(err, intl)
                })
        }
    }

    const downloadPdf = async (id, url) => {
        let load = [];
        load[id] = true;

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: url
            }))

        } else {
            setLoadingDownload(load)

            fetch(url).then((response) => {
                response.blob().then((blob) => {

                    // Creating new object of PDF file
                    const fileURL =
                        window.URL.createObjectURL(blob);

                    // Setting various property values
                    let alink = document.createElement("a");
                    alink.href = fileURL;
                    alink.download = 'contract-' + id + '.pdf';
                    alink.click();
                    setLoadingDownload([])
                });
            });


        }
    }

    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUser(response.data ?? [])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'contracts.title'})}</h1>
                </div>
                <div className={"col-12 pb-4"}>
                    <div className="card">

                        <div className={"header"}>
                            <p>{intl.formatMessage({id: 'contracts.title'})}</p>
                        </div>


                        {
                            contracts.length === 0 ?
                                <div className={"content"}>

                                    <div
                                        className={"alert alert-warning"}>{intl.formatMessage({id: 'contracts.alert_no_contracts_found'})}</div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table contracts-client mb-1"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>
                                                <div
                                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'events.contracts.date'})}</div>
                                                <div
                                                    className={"col-3 table-td"}>{intl.formatMessage({id: 'general.events'})}</div>
                                                <div
                                                    className={"col-2 table-td"}>{intl.formatMessage({id: 'general.status'})}</div>
                                                <div className={"col-4 table-td"}></div>
                                            </div>
                                        </div>

                                        <div className={"table-body"}>
                                            {
                                                contracts.map((contract, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-1 table-td"}>
                                                                {contract.series}{contract.number}
                                                            </div>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                {moment(contract.date).format('Do MMMM YYYY')}
                                                            </div>
                                                            <div
                                                                className={"col-3 table-td"}>
                                                               {contract.events.map((item, i) => {
                                                                   return item.title + (i !== contract.events.length-1 ? ', ' : '')
                                                               })}

                                                            </div>
                                                            <div
                                                                className={"col-2 table-td"}>
                                                                <div
                                                                    className={"alert alert-sm alert-" + (contract.status === 'new' ? 'danger' :
                                                                        (contract.status === 'send' || contract.status === 'mentions' ? 'warning' : 'success'))}>
                                                                    {intl.formatMessage({id: 'contracts.status.' + contract.status})}
                                                                </div>
                                                            </div>
                                                            <div className={"col-4 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    icon={"fa-solid fa-download"}
                                                                    tooltip={intl.formatMessage({id: 'general.buttons.download'})}
                                                                    whiteLoader={false}
                                                                    loading={loadingDownload[contract.id] ?? false}
                                                                    disabled={loadingDownload[contract.id] ?? false}
                                                                    onClick={() => {
                                                                        if (contract.is_pdf && contract.pdf && contract.pdf.url)
                                                                            downloadPdf(contract.id, process.env.REACT_APP_API + contract.pdf.url)
                                                                        else
                                                                            download(contract.id, contract.company)
                                                                    }}
                                                                />

                                                                {contract.annexes > 0 && <div>
                                                                    <Button
                                                                        className={"btn-light btn-edit ms-auto"}
                                                                        icon={"fa-solid fa-file"}
                                                                        tooltip={intl.formatMessage({id: 'events.contracts.annexes'})}
                                                                        whiteLoader={false}
                                                                        onClick={() => navigate('/contracts/' + contract.id + '/annexes')}
                                                                    />
                                                                    <div style={{position: "relative"}}>
                                                                        <div className={"badge bg-danger"}>{contract.unsigned_annexes}</div>
                                                                    </div>
                                                                </div>}
                                                                <Button
                                                                    className={"btn-primary ms-auto " + (contract.status === 'signed' ? 'disabled' : '')}
                                                                    value={intl.formatMessage({id: contract.is_pdf ? 'contracts.client.add_mentions' : 'contracts.client.sign'})}
                                                                    disabled={contract.status === 'signed'}
                                                                    onClick={() => navigate('/contracts/' + contract.id)}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer d-flex justify-content-center"}>
                            <Paginate
                                page={page}
                                setPage={setPage}
                                totalItems={total}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

