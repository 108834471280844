import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import Button from "../../elements/Button/Button";
import {toast} from "react-toastify";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import ModalComp from "../../../components/ModalComp/ModalComp";
import Selector from "../../elements/Selector/Selector";
import {downloadEfactura, downloadXml} from "../../../utils/functions";

export default function InvoicesTable({invoices, getData, selectedInvoices, setSelectedInvoices,back, event = null,access = true}) {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    const optionsOblio = [{
        value: 'Chitanta',
        label: 'Chitanta'
    }, {
        value: 'Bon fiscal',
        label: 'Bon fiscal'
    }, {
        value: 'Alta incasare numerar',
        label: 'Alta incasare numerar'
    }, {
        value: 'Ordin de plata',
        label: 'Ordin de plata'
    }, {
        value: 'Mandat postal',
        label: 'Mandat postal'
    }, {
        value: 'Card',
        label: 'Card'
    }, {
        value: 'CEC',
        label: 'CEC'
    }, {
        value: 'Bilet ordin',
        label: 'Bilet ordin'
    }, {
        value: 'Alta incasare banca',
        label: 'Alta incasare banca'
    }]
    const optionsSmartbill = [{
        value: 'Chitanta',
        label: 'Chitanta'
    }, {
        value: 'Ordin plata',
        label: 'Ordin plata'
    }, {
        value: 'Mandat postal',
        label: 'Mandat postal'
    }, {
        value: 'Card',
        label: 'Card'
    }, {
        value: 'CEC',
        label: 'CEC'
    }, {
        value: 'Card online',
        label: 'Card online'
    }, {
        value: 'Extras de cont',
        label: 'Extras de cont'
    }, {
        value: 'Ramburs',
        label: 'Ramburs'
    }, {
        value: 'Alta incasare',
        label: 'Alta incasare'
    }]

    const [loadingDownload, setLoadingDownload] = useState([])
    const [loadingDelete, setLoadingDelete] = useState([])
    const [loadingCancel, setLoadingCancel] = useState([])
    const [user, setUser] = useState(null)
    const [invoiceEfacturaSelected, setInvoiceEfacturaSelected] = useState(null)
    const [loadingSendEfactura, setLoadingSendEfactura] = useState(false)
    const [modalEfacturaIsOpen, setModalEfacturaIsOpen] = useState(false)
    const [modalIsOpenCollect, setModalIsOpenCollect] = useState(false)
    const [invoiceSelected, setInvoiceSelected] = useState('')
    const [loadingCollect, setLoadingCollect] = useState(false)
    const [collect, setCollect] = useState('')
    const [docNr, setDocNr] = useState(0);
    const [options, setOptions] = useState([]);
    const [seriesChit, setSeriesChit] = useState('');

    const download = async (invoice, event) => {
        let load = [];
        load[invoice.id] = true;

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: process.env.REACT_APP_API + '/invoices/download/' + invoice.id + '/' + user.id + '/' + event.id,
            }))

        } else {
            setLoadingDownload(load)
            axios.get(process.env.REACT_APP_API + '/invoices/download/' + invoice.id + '/' + user.id + '/' + event.id, {
                responseType: 'blob',
                headers: {
                    // 'Accept': 'application/octet-stream',
                    'Content-Type': 'application/pdf',
                }
            }).then((response) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    response.data,
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'invoice-' + invoice.id + '.pdf',
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);


                setLoadingDownload([])
            })
                .catch(err => {
                    errorParser(err, intl)

                    setLoadingDownload([])
                })
        }


    }

    const sendToClient = async (id, status) => {

        let payload = {
            status: status,
        }
        await axios.put(process.env.REACT_APP_API + '/invoices/change-status/' + id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                getData()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const sendEfactura = async (id) => {
        setLoadingSendEfactura(true)
        await axios.get(process.env.REACT_APP_API + '/invoices/efactura/send/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setModalEfacturaIsOpen(false)
                setInvoiceEfacturaSelected(null)
                getData()

                if (response.data.status === 1){
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                }else
                    toast.error(intl.formatMessage({id: 'errors.unknown'}))
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setLoadingSendEfactura(false)
    }
    const deleteInvoice = async (id) => {

        await axios.delete(process.env.REACT_APP_API + '/invoices/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1 || response.data.status === 200) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    getData()
                    setLoadingDelete([])
                } else {
                    if (response.data.error)
                        toast.error(response.data.error)
                    else {
                        toast.error(intl.formatMessage({id: 'errors.invoice_cannot_delete'}))
                    }
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const cancelInvoice = async (id) => {

        await axios.get(process.env.REACT_APP_API + '/invoices/cancel/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1 || response.data.status === 200) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    getData()
                    setLoadingCancel([])
                } else {
                    toast.error(response.data.error)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const toggleSelectInvoice = (invoiceId) => {

        if (selectedInvoices.includes(invoiceId)) {
            setSelectedInvoices(selectedInvoices.filter((item) => item !== invoiceId))
        } else {
            setSelectedInvoices([...selectedInvoices, invoiceId])
        }

    }

    const collectInvoice = async (e) => {
        e.preventDefault()
        setLoadingCollect(true)
        axios.post(process.env.REACT_APP_API + '/invoices/collect/' + invoiceSelected.id, {
            collect,
            number: docNr,
            series: seriesChit
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1 || response.data.status === 200) {
                    getData()
                } else {
                    toast.error(response.data.error)
                }
                setModalIsOpenCollect(false)
                setLoadingCollect(false)
            })
            .catch(err => {
                setModalIsOpenCollect(false)
                errorParser(err, intl)
                setLoadingCollect(false)
            })
    }
    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const getSeries = async () => {
        axios.get(process.env.REACT_APP_API + '/invoices/series', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {

                if (response.data.error) {
                    toast.error(response.data.error)
                } else {
                    let temp = []
                    response.data.data.forEach((item) => {
                        if (item.type === 'Chitanta' || item.type === 'c')
                            temp.push({
                                value: item.name,
                                label: item.name,
                                number: item.next,
                            })
                    })
                    setOptions(temp)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUser(response.data ?? [])
                getSeries()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    return (
        <div className={"content p-0"}>
            <div className={"table mb-1"}>
                <div className={"table-head"}>
                    <div className={"row"}>
                        <div
                            className={"col-1 table-td"}>
                            <input type={"checkbox"}
                                   onChange={() => {
                                       if (selectedInvoices.length === invoices.length) {
                                           setSelectedInvoices([])
                                       } else {
                                           setSelectedInvoices(invoices.map((item) => item.id))
                                       }
                                   }}
                                   value={'all'}
                                   checked={selectedInvoices.length > 0 && selectedInvoices.length === invoices.length}
                            /></div>

                        <div
                            className={"col-1 table-td"}>
                            {intl.formatMessage({id: 'invoices.generate_with'})}
                        </div>
                        <div className={"table-td col-1"}>{intl.formatMessage({id: 'events.invoices.date'})}</div>
                        {
                            event === null &&
                            <div className={"col-1 table-td"}>{intl.formatMessage({id: 'general.event'})}</div>
                        }
                        <div
                            className={"table-td col-1"}>{intl.formatMessage({id: 'general.status'})}</div>
                        <div className={"table-td col-1"}>{intl.formatMessage({id: 'general.client'})}</div>
                        <div className={"table-td col-1"}>{intl.formatMessage({id: 'general.efactura_status'})}</div>
                        <div className={"table-td " + (event ? 'col-5' : 'col-6')}></div>
                    </div>
                </div>


                <div className={"table-body"}>
                    {
                        invoices.map((invoice, i) => (
                            <div className={"table-row"} key={i}>
                                <div className={"row"}>
                                    <div
                                        className={"col-1 table-td"}>
                                        <input type={"checkbox"} className={"me-2"}
                                               onChange={() => toggleSelectInvoice(invoice.id)}
                                               value={invoice.id}
                                               checked={selectedInvoices.includes(invoice.id)}/>

                                        {invoice.series}{invoice.number}
                                    </div>
                                    <div
                                        className={"col-1 table-td"}>

                                        {capitalizeFirstLetter(invoice.type)}
                                    </div>
                                    <div
                                        className={"table-td col-1"}>
                                        {moment(invoice.date).format('DD/MM/YY')}
                                    </div>
                                    {
                                        event === null &&
                                        <div
                                            className={"col-1 table-td"}>
                                            {invoice.event.event_type && intl.formatMessage({id: 'general.event_type.' + invoice.event.event_type})}
                                            {invoice.event.event_type_for_event_planner && invoice.event.event_type_for_event_planner.title}: {invoice.event.title}

                                        </div>
                                    }

                                    <div
                                        className={"table-td col-1"}>
                                        <div
                                            className={"alert alert-sm alert-" + (invoice.status === 'new' || invoice.status === 'canceled' ? 'danger' :
                                                (invoice.status === 'send' || invoice.status === 'mentions' ? 'warning' : 'success'))}>
                                            {intl.formatMessage({id: 'invoices.status.' + invoice.status})}
                                        </div>
                                    </div>
                                    <div
                                        className={"table-td col-1"}>
                                        {invoice.event.type}
                                        {
                                            invoice.event.client_type === 'company' ?
                                                invoice.event.client.company_title
                                                :
                                                invoice.event.client.first_name + ' ' + invoice.event.client.last_name

                                        }
                                    </div>
                                    <div
                                        className={"table-td col-1"}>
                                        {invoice.type === 'venner' && <div
                                            style={{cursor: "pointer"}}
                                            onClick={() => {
                                                if (access){
                                                    setModalEfacturaIsOpen(true)
                                                    let temp = invoice
                                                    temp.canSendEfactura = false
                                                    if (invoice.content && invoice.content.client && invoice.content.client.county
                                                        && invoice.content.client.cui && invoice.content.client.name
                                                        && invoice.content.client.address && invoice.content.client.city
                                                        && ((invoice.content.company.vat_payer && invoice.content.totals && invoice.content.totals.length > 0) || invoice.content.company.vat_payer === false)
                                                        && invoice.content.company && invoice.content.company.county
                                                        && invoice.content.company.cui && invoice.content.company.name
                                                        && invoice.content.company.address_1 && invoice.content.company.city)
                                                        temp.canSendEfactura = true
                                                    setInvoiceEfacturaSelected(temp)
                                                }
                                            }}
                                            className={"alert alert-sm alert-" + (invoice.efactura_status !== null ? (invoice.efactura_status.status === 'completed' ? 'success' :
                                                (invoice.efactura_status.status === 'rejected' ? 'danger' : 'warning')) : 'warning')}>
                                            {intl.formatMessage({id: 'invoices.status_efactura.' + (invoice.efactura_status !== null ? invoice.efactura_status.status : 'unsent')})}
                                        </div>}
                                    </div>
                                    <div className={"table-td " + (event ? 'col-6' : 'col-5')}>
                                        <Button
                                            className={"btn-light btn-edit ms-auto"}
                                            icon={"fa-solid fa-download"}
                                            tooltip={intl.formatMessage({id: 'general.buttons.download'})}
                                            whiteLoader={false}
                                            loading={loadingDownload[invoice.id] ?? false}
                                            onClick={() => download(invoice, invoice.event)}
                                        />

                                        {access && invoice && invoice.content && invoice.content.company && invoice.content.company.currency === 'ron' && <Button
                                            disabled={invoice.type !== 'venner' || invoice.status === 'payed' || (invoice.event && invoice.event.ended)}
                                            className={"btn-light btn-edit " + (invoice.type !== 'venner' || invoice.status === 'payed' || (invoice.event && invoice.event.ended) ? 'disabled' : '')}
                                            icon={"fa-solid fa-pen"}
                                            tooltip={intl.formatMessage({id: 'general.edit'})}
                                            onClick={() => navigate(back + invoice.id + '?back=' + back)}
                                        />}
                                        {access && <Button
                                            className={"btn-light btn-delete " + ((invoice.status === 'canceled' || invoice.efactura_status || (invoice.event && invoice.event.ended)) ? 'disabled' : '')}
                                            icon={"fa-solid fa-ban"}
                                            tooltip={intl.formatMessage({id: 'general.buttons.cancel'})}
                                            whiteLoader={false}
                                            disabled={invoice.status === 'canceled' || invoice.efactura_status || (invoice.event && invoice.event.ended)}
                                            loading={loadingCancel[invoice.id] ?? false}
                                            onClick={() => cancelInvoice(invoice.id)}
                                        />}
                                        {access && <Button
                                            className={"btn-light btn-delete "  + (invoice.event && invoice.event.ended ? 'disabled' : '')}
                                            icon={"fa-solid fa-trash"}
                                            tooltip={intl.formatMessage({id: 'general.buttons.delete'})}
                                            whiteLoader={false}
                                            disabled={(loadingDelete[invoice.id] || (invoice.event && invoice.event.ended))}
                                            loading={loadingDelete[invoice.id] ?? false}
                                            onClick={() => deleteInvoice(invoice.id)}
                                        />}
                                        <div className={"ms-2 d-grid"}>
                                            {access && <Button
                                                disabled={invoice.status === 'payed'}
                                                value={intl.formatMessage({id: 'general.buttons.payment'})}
                                                className={"btn-primary align-items-center ms-auto float-right " + (invoice.status === 'payed' ? 'disabled' : '')}
                                                onClick={() => {
                                                    if (invoice.type === 'oblio') {
                                                        setInvoiceSelected(invoice)
                                                        setModalIsOpenCollect(true)
                                                    } else {
                                                        if (invoice.type === 'smartbill') {
                                                            setInvoiceSelected(invoice)
                                                            setModalIsOpenCollect(true)
                                                        } else
                                                            sendToClient(invoice.id, 'payed')
                                                    }
                                                }}
                                            />}
                                        </div>
                                        {access && <div className={"ms-2 d-grid"}>
                                            <Button
                                                //disabled={invoice.status !== 'new'}
                                                value={intl.formatMessage({id: 'general.buttons.send_to_client'})}
                                                className={"btn-primary align-items-center ms-auto float-right" /*+ (invoice.status !== 'new' ? 'disabled' : '')*/}
                                                onClick={() => sendToClient(invoice.id, 'send')}
                                            />
                                            {
                                                invoice.email_sent_at &&
                                                <div className={"email-sent-at"}>
                                                    {intl.formatMessage({id: 'events.email_sent_at'})} {moment(invoice.email_sent_at).format('Do/MM/YY H:mm')}
                                                </div>
                                            }
                                        </div>}

                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>


            <ModalComp
                title={intl.formatMessage({id: 'invoices.collect_bill'})}
                isOpen={modalIsOpenCollect}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpenCollect(false)}
            >

                <form onSubmit={(e) => collectInvoice(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'invoices.doc_type'})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={invoiceSelected.type === 'oblio' ? [...optionsOblio] : [...optionsSmartbill]}
                                required={true}
                                value={collect}
                                onChange={(option) => {
                                    setCollect(option.value)
                                }}
                                isSearchable={false}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                        {collect === 'Chitanta' && options.length === 0 &&
                            <div
                                className={"alert alert-warning mt-2"}>{intl.formatMessage({id: 'invoice.cannot_collect_invoice'})} {invoiceSelected.type}!</div>
                        }
                        {collect === 'Chitanta' ?
                            <div>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'invoice.receipt_series'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={[...options]}
                                    required={true}
                                    onChange={(option) => {
                                        setSeriesChit(option.value)
                                    }}
                                    isSearchable={false}
                                />
                            </div>
                            :
                            invoiceSelected.type === 'oblio' &&
                            <div>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'invoice.doc_number'})}<span
                                    className={"form-required"}>*</span></div>

                                <input type={"text"} className={"form-input"} id={"sdfdsdsf"}
                                       required={true}
                                       onChange={(e) => setDocNr(e.target.value)}/>
                            </div>
                        }

                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    loading={loadingCollect}
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={intl.formatMessage({id: 'invoices.generate'})}
                                    type={'submit'}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
            <ModalComp
                title={intl.formatMessage({id: 'general.efactura_status'}) +  (invoiceEfacturaSelected ? (': ' + invoiceEfacturaSelected.series + invoiceEfacturaSelected.number) : '')}
                isOpen={modalEfacturaIsOpen}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => {
                    setInvoiceEfacturaSelected(null)
                    setModalEfacturaIsOpen(false)
                }}
            >
                {
                    user && user.efactura_configured === 1?
                        (invoiceEfacturaSelected && invoiceEfacturaSelected.efactura_status === null ?
                        <div className={"content"} style={{paddingBottom: '200px'}}>
                           <div>
                               {intl.formatMessage({id: 'general.status'})}: {intl.formatMessage({id: 'invoices.status_efactura.unsent'})}
                           </div>

                            {
                                invoiceEfacturaSelected.canSendEfactura === true ?
                                    <Button
                                        loading={loadingSendEfactura}
                                        className={"btn-primary mt-4 ms-auto"}
                                        style={{width: '100%'}}
                                        value={intl.formatMessage({id: 'invoices.send_efactura'})}
                                        type={'button'}
                                        onClick={() => {
                                            sendEfactura(invoiceEfacturaSelected.id)
                                        }}
                                    />
                                    :
                                    <div className={"alert alert-info mt-4"}>{intl.formatMessage({id: 'general.cant_send_efactura'})}</div>
                            }


                        </div>
                        :
                        invoiceEfacturaSelected && invoiceEfacturaSelected.efactura_status !== null &&
                        <div className={"content"} style={{paddingBottom: '200px'}}>
                            {intl.formatMessage({id: 'general.status'})}: {intl.formatMessage({id: 'invoices.status_efactura.' + invoiceEfacturaSelected.efactura_status.status})}

                            {
                                (invoiceEfacturaSelected.efactura_status.status === 'rejected' || invoiceEfacturaSelected.efactura_status.status === 'completed') &&
                                <Button
                                    loading={loadingSendEfactura}
                                    className={"btn-primary mt-4 ms-auto"}
                                    style={{width: '100%'}}
                                    value={intl.formatMessage({id: 'invoices.download_efactura'})}
                                    type={'button'}
                                    onClick={() => {
                                        if (invoiceEfacturaSelected.efactura_status.status === 'completed')
                                            downloadEfactura(invoiceEfacturaSelected.efactura_status.efactura_download_id,invoiceEfacturaSelected.efactura_status.efactura_id, intl, jwt, setLoadingDownload)
                                        else
                                            downloadXml(invoiceEfacturaSelected.efactura_status.efactura_download_id,invoiceEfacturaSelected.efactura_status.efactura_id, intl, jwt,setLoadingDownload)

                                     }}
                                />
                            }
                        </div>)
                        :
                        <>

                            <div className={"alert alert-info"}>{intl.formatMessage({id: 'general.efactura_is_not_configured'})}</div>
                            <Button
                                loading={loadingSendEfactura}
                                className={"btn-primary mt-4 ms-auto"}
                                style={{width: '100%'}}
                                value={intl.formatMessage({id: 'general.configure_efactura'})}
                                type={'button'}
                                onClick={() => {
                                    navigate('/company/e-factura')
                                }}
                            />
                        </>

                }

            </ModalComp>
        </div>
    );
}

